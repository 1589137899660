// src/components/ThreeDScene.js
import React, { useState, useEffect, useRef } from 'react';
import * as THREE from 'three';
import NET from 'vanta/dist/vanta.net.min.js';

const ThreeDScene = () => {
    const [vantaEffect, setVantaEffect] = useState(0); // Added this + import useState
    const myRef = useRef(null);

    useEffect(() => {
        if (!vantaEffect) { // <-- Added this conditional
            NET({
                THREE: THREE,
                el: myRef.current,
                mouseControls: true,
                touchControls: true,
                gyroControls: false,
                minHeight: 200.00,
                minWidth: 200.00,
                scale: 1.00,
                scaleMobile: 1.00,
                color: 0x00df9a,
                backgroundColor: 0x00000,
                points: 25.0, // Adjust the number of points
                maxDistance: 28.0, // Adjust the maximum distance between points
            });
        }

        return () => {
            if (vantaEffect) { // <-- Used vantaEffect here
                vantaEffect.destroy();
            }
        };
    }, []);


    return <div ref={myRef} className="absolute inset-0 h-full w-full z-0"></div>;
};

export default ThreeDScene;
